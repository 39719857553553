import Button from 'components/atoms/button'
import Link from 'components/atoms/link'
import SubHead from 'components/atoms/subhead'
import Container from 'components/container'
import Layout from 'components/layout'
import Breadcrumbs from 'components/molecules/breadcrumbs'
import Card from 'components/molecules/card'
import Seo from 'components/molecules/seo'
import ArticleBody from 'components/organisms/articleBody'
import ArticleHeader from 'components/organisms/articleHeader'
import CTABlock from 'components/organisms/ctaBlock'
import { renderDocumentToReactComponents } from 'components/organisms/renderDocumentToReactComponents'
import Share from 'components/organisms/share'
import { graphql, PageProps } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useResourceTextData } from 'hooks/useResourceTextData'
import * as React from 'react'
import { ContentfulJob } from 'types/graphql-types'

type JobTemplateProps = PageProps & {
  data: {
    contentfulJob: ContentfulJob
  }
  pageContext: {
    nextArticle: ContentfulJob
  }
}

const JobTemplate = (props: JobTemplateProps): JSX.Element => {
  const { contentfulJob } = props.data
  const {
    title,
    heroImage,
    leadingParagraph,
    body,
    metaDescription,
    location,
    applyText,
    applyEmail,
    applyLink,
  } = contentfulJob
  const hasImage = heroImage !== null
  return (
    <Layout>
      <Seo
        title={title}
        imageUrl={
          hasImage
            ? heroImage.fluid.src.replace(/^\/\//, 'https://')
            : undefined
        }
        description={metaDescription?.metaDescription}
        url={props.location.href}
      />

      <ArticleHeader hasImage={hasImage}>
        <Container>
          <Breadcrumbs>
            <Link to="/careers">
              {useResourceTextData('careers.title', 'Careers')}
            </Link>
          </Breadcrumbs>
          <Card
            size="lg"
            title={title}
            subTitle={{ title: location }}
            image={heroImage}
            hero
            share
          >
            {applyText && (applyLink || applyEmail) && (
              <AnchorLink to={`${props.location.pathname}#apply`}>
                <Button alternate>
                  {useResourceTextData('careers.applyHere', 'Apply here')}
                </Button>
              </AnchorLink>
            )}
          </Card>
        </Container>
      </ArticleHeader>

      <ArticleBody hasImage={hasImage}>
        <Container>
          <div className="row">
            <div className="meta">
              <Share />
            </div>
            <div className="content">
              {!hasImage && (
                <div className="tablet-title">
                  <SubHead className="sub-title">{location}</SubHead>
                  <h1 className="title">{title}</h1>
                  {applyText && (applyLink || applyEmail) && (
                    <AnchorLink to={`${props.location.pathname}#apply`}>
                      <Button alternate>
                        {useResourceTextData('careers.applyHere', 'Apply here')}
                      </Button>
                    </AnchorLink>
                  )}
                </div>
              )}
              {leadingParagraph?.leadingParagraph && (
                <p className="lead">{leadingParagraph?.leadingParagraph}</p>
              )}
              {body && renderDocumentToReactComponents(body)}
            </div>
            <div />
          </div>
        </Container>
      </ArticleBody>

      {applyText && (applyLink || applyEmail) && (
        <>
          <a id="apply" />
          <CTABlock
            body={applyText.applyText}
            cta={[
              {
                id: 0,
                internal: { type: 'ContentfulLink' },
                link: applyLink || `mailto:${applyEmail}`,
                title: useResourceTextData('careers.applyHere', 'Apply here'),
              },
            ]}
            alternate
            noMarginPadding
          />
        </>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ContentFulJob($slug: String, $locale: String) {
    contentfulJob(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      title
      slug
      node_locale
      heroImage {
        fluid {
          ...Image
        }
      }
      leadingParagraph {
        leadingParagraph
      }
      body {
        raw
        references {
          ... on Node {
            ... on ContentfulImagesContentBlock {
              contentful_id
              internal {
                type
              }
              images {
                fluid {
                  ...Image
                }
              }
            }
            ... on ContentfulAsset {
              contentful_id
              fluid {
                ...Image
              }
            }
            ... on ContentfulVideo {
              contentful_id
              internal {
                type
              }
              url
            }
          }
        }
      }
      metaDescription {
        metaDescription
      }
      location
      applyText {
        applyText
      }
      applyEmail
      applyLink
    }
  }
`

export default JobTemplate
